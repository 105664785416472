<template>
  <div class="my-page-following my-page" ref="containerRef">
    <ul class="nav">
      <li class="nav-item" v-for="(m, idx) in state.menus" :key="idx">
        <router-link :to="m.path" class="btn" :class="{'badge-point-soft':$route.path === m.path}"> {{ m.title }}</router-link>
      </li>
    </ul>
    <div class="wrapper">
      <div class="row" v-if="state[$route.params.param2].loaded && state[$route.params.param2].list.length > 0">
        <div class="item col-lg-6" v-for="(f, idx) in state[$route.params.param2].list" :key="idx">
          <FollowCard
              :memberName="f.memberName"
              :memberSeq="f.makerSeq || f.memberSeq"
              :skeleton="f.skeleton ? f.skeleton : !state[$route.params.param2].loaded"
              :supportCount="f.supportCount"
              :openedCount="f.openedCount"
          />
        </div>
      </div>
      <div class="row" v-else-if="!state[$route.params.param2].loaded">
        <div class="item col-lg-6" v-for="i in 8" :key="i">
          <FollowCard
              memberName="Wait a moment"
              :skeleton="true"
              :supportCount="0"
          />
        </div>
      </div>
      <div v-else>
        <NoData/>
      </div>
    </div>
  </div>
</template>
<script>
import Follow from "@/components/FollowCard";
import FollowCard from "@/components/FollowCard";
import mixin from "@/scripts/mixin";
import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import NoData from "@/components/NoData";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageMypageFollowing";
  this.initialize = initialize;
}

export default defineComponent({
  components: {FollowCard, NoData, Follow},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("setFollows");
      loadContent();
    });

    const state = reactive({
      menus: [{
        name: "followings",
        title: "팔로잉",
        path: "/mypage/follow/followings",
      }, {
        name: "followers",
        title: "팔로워",
        path: "/mypage/follow/followers",
      }],
      followings: {
        list: [],
        loaded: false,
      },
      followers: {
        list: [],
        loaded: false,
      },
      more: false,
      pagination: null,
      args: {
        pageIndex: 1,
        pageUnit: 10,
        pageSize: 5,
      }
    });

    const containerRef = ref();

    const loadContent = (more) => {
      if (more) {
        state.args.pageIndex += 1;
        state.more = true;

        for (let i = 0; i < 4; i += 1) {
          state[router.app.$route.params.param2].list.push({
            memberSeq: "000000",
            memberName: "Wait",
            supportCount: 0,
            skeleton: true
          });
        }

      } else {
        state.args.pageIndex = 1;
        state[router.app.$route.params.param2].loaded = false;
        state.more = false;
      }

      http.get(`/api/channel/${store.state.account.memberSeq}/${router.app.$route.params.param2}`, state.args).then(({data}) => {
        state[router.app.$route.params.param2].loaded = true;
        state.more = false;
        state.pagination = data.body.paginationInfo;

        if (more) {
          state[router.app.$route.params.param2].list = state[router.app.$route.params.param2].list.filter(f => !f.skeleton);

          for (let i in data.body.list) {
            state[router.app.$route.params.param2].list.push(data.body.list[i]);
          }
          return;
        }

        state[router.app.$route.params.param2].list = data.body.list;
      });
    };

    const onScroll = () => {
      if (!state[router.app.$route.params.param2].loaded || state.args.pageIndex >= state.pagination.lastPageNo || state.more) {
        return;
      }

      const itemHeight = containerRef?.value.querySelector(".item").offsetHeight;

      itemHeight >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
      && loadContent(true);
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state, containerRef};
  }
});
</script>
<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-following {
  > .wrapper {
    > .row {
      margin-top: $px-15;
    }

    .no-data {
      padding: $px25;
      border: 1px solid $colorBorder;
    }
  }
}
</style>